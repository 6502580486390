import React from 'react';
import MilkLink from './milk-link';

const NewsCta = ({ title, text, btn, url, lang }) => {
  return (
    <div className='news-cta' data-sal='slide-up' data-sal-duration='1000'>
      <h2
        className='news-cta__title'
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <div className='text' dangerouslySetInnerHTML={{ __html: text }} />
      <MilkLink className='btn' to={`/${lang}${url}`}>
        {btn}
      </MilkLink>
    </div>
  );
};

export default NewsCta;
