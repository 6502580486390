import React from 'react';
import Layout from '../components/layout';
import Svg from '../components/svg';
import PageLink from '../components/page-link';
import SEO from '../components/seo';
import A80 from '../animations/a80';
import A160 from '../animations/a160';
import A400 from '../animations/a400';
import NewsCta from '../components/news-cta';

export default ({ pageContext: { item, pages, lang, texts, key, langs } }) => (
  <Layout pages={pages} lang={lang} texts={texts} langs={langs}>
    <SEO
      description={item.seo_description}
      title={item.seo_title}
      image={item.seo_image}
    />
    <div className='layer-top layer-top-post'>
      <A160 className='new-liquid-1' />
      <A400 className='new-liquid-2' />
      <div className='relative'>
        <div className='small-wrap'>
          <PageLink
            data-sal='slide-right'
            data-sal-duration='1000'
            className='back back-dark'
            lang={lang}
            pages={pages}
            to='news'
          >
            <Svg name='back' />
            Go back
          </PageLink>
          {/* <div
            data-sal='fade'
            data-sal-duration='1000'
            className='layer-label simple'
          >
            {item.date}
          </div> */}
          <h1
            data-sal='slide-left'
            data-sal-duration='1000'
            className='h2 news-title'
            dangerouslySetInnerHTML={{ __html: item.title }}
          />
        </div>
        <div className='mid-wrap mid-wrap-post'>
          {item.image_desktop && item.image_desktop !== null && (
            <img
              data-sal='slide-up'
              data-sal-duration='1000'
              className='mobile-hide'
              src={item.image_desktop}
              alt={item.title}
              title={item.title}
            />
          )}
          {item.image_mobile && item.image_mobile !== null && (
            <img
              data-sal='slide-up'
              data-sal-duration='1000'
              className='desktop-hide'
              src={item.image_mobile}
              alt={item.title}
              title={item.title}
            />
          )}
          <div className=''>
            <A80 className='new-liquid-3' />
            <div className='group group-even'>
              <div className='left'>
                <div
                  data-sal='slide-right'
                  data-sal-duration='1000'
                  className='h3'
                  dangerouslySetInnerHTML={{ __html: item.subtitle }}
                />
                {texts.is_news_inner_cta == true ? 
                  <NewsCta
                  title={texts.news_inner_cta_title}
                  text={texts.news_inner_cta_text}
                  btn={texts.news_inner_cta_btn}
                  url={texts.news_inner_cta_url}
                  lang={lang}
                />
                 : ``}
              </div>
              <div
                data-sal='slide-left'
                data-sal-duration='1000'
                className='right news-inner'
                dangerouslySetInnerHTML={{ __html: item.text }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
